import Cocoen from "cocoen";
import TinyGesture from 'tinygesture';

new Cocoen(document.querySelector('.cocoen-2'),{
    start: 33,
    dragCallback: updateText,
});

new Cocoen(document.querySelector('.cocoen'));

let playButton = document.querySelector('#playwideo');
let video = document.querySelector('video#oparku');

playButton.addEventListener('click',() => {
    video.play();
    playButton.style.display = 'none';
});

let modal = document.querySelector('#modal');
let modalNav = document.querySelector('#modal nav');
let modalNext = document.querySelector('.modal .next');
let modalPrev = document.querySelector('.modal .prev');

document.querySelector("#modal button").addEventListener('click',() => {
    modal.classList.remove('visible');
})

document.querySelectorAll(".row div").forEach((element) => {
    element.addEventListener('click',() => {
        modal.classList.add('visible');
        modal.dataset.current = element.dataset.column;
        setModalContent(element);
    });
});

modalPrev.addEventListener('click', (el) => {
    let prev = parseInt(modal.dataset.current )- 1;
    if (prev < 1) prev = 35;
    element = document.querySelector(`[data-column="${prev}"]`);
    setModalContent(element);

});

modalNext.addEventListener('click', (el) => {
    let next = parseInt( modal.dataset.current ) + 1;
    if(next > 35) next=1;
    element = document.querySelector(`[data-column="${next}"]`);
    setModalContent(element);

});

const gesture = new TinyGesture(modal);

gesture.on('swiperight', event => {
    modalPrev.click();
});

gesture.on('swipeleft', event => {
  modalNext.click()
});

let amount = document.querySelector('.amount-value span')

function updateText(el){
    if (el < 0.5) {
        el = el-0.01
    }
    if (el > 0.97) {
        el =1;
    }
    amount.innerHTML = (el.toFixed(2) * 10000).toFixed(0);
}

document.querySelector('.menu-toggle').addEventListener('click', (e) => {
    document.body.classList.toggle('toggled');
});

function setModalContent(element){
    modal.querySelector('.inner').innerHTML = element.querySelector('.content').innerHTML;
    modal.querySelector('header img').src = element.querySelector('img').src;
    modal.dataset.current = element.dataset.column;
    modal.querySelector('.count').innerHTML = element.dataset.column;

    if(element.dataset.column > 0) setClassName('section1');
    if(element.dataset.column > 7) setClassName('section2');
    if(element.dataset.column > 14) setClassName('section3');
    if(element.dataset.column > 21) setClassName('section4');
    if(element.dataset.column > 28) setClassName('section5');
}

function setClassName(addClassName){
    modalNav.className= '';
    modalNav.classList.add(addClassName);
    modal.querySelector('header').className = '';
    modal.querySelector('header').classList.add(addClassName);
}